import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Services | DriveCraft Customs & Performance Center
			</title>
			<meta name={"description"} content={"Сучасна станція технічного обслуговування, що спеціалізується на видатному тюнінгу та створенні індивідуальних вихлопних систем для вашого автомобіля."} />
			<meta property={"og:title"} content={"Services | DriveCraft Customs & Performance Center"} />
			<meta property={"og:description"} content={"Сучасна станція технічного обслуговування, що спеціалізується на видатному тюнінгу та створенні індивідуальних вихлопних систем для вашого автомобіля."} />
			<link rel={"shortcut icon"} href={"https://bertapub.net/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bertapub.net/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bertapub.net/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://bertapub.net/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Components.Services />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652405d247aa25001fafd00a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});